import React, { Component } from 'react'

import './confirm_1.scss';
let self = null
export default class Banner extends Component {
    constructor(props){
        super(...arguments)
        this.state = {
            
        }
        this.linkTo = this.linkTo.bind(this)
    }
    static defaultProps = {
        title: '确认',
        isTitle: true,
        content: '',
        escText: '取消',
        confirmText: '确认',
        textCenter: false
    }
    componentWillMount () {
        self = this
        console.log(self)
    }
    linkTo(item,e){
        if(item&&item.url){
            self.props.history.push(item.url)
        }
    }
    cancelDialog(){
        self.props.Cancel && self.props.Cancel()
    }
    confirmDialog(){
        self.props.Confirm && self.props.Confirm()
    }

    render() {
        return <div className="dialog_confirm_1">
            <div className='shadow' onClick={this.cancelDialog}></div>
            <div className='main flex_center_box'>
                <div className='center'>
                    {
                        this.props.isTitle ? <div className='title'>{this.props.title}</div> : null
                    }
                    {
                        this.props.children ? <div className='main_bd'>
                            {this.props.children}
                        </div>:null
                    }
                    {
                        this.props.content ? <div className={this.props.textCenter?'main_bd t_c':'main_bd'}>{this.props.content}</div> : null
                    }
                    <div className='buttons flex_box'>
                        <div className='esc fb1' onClick={this.cancelDialog}>{this.props.escText}</div>
                        <div className='confirm fb1' onClick={this.confirmDialog}>{this.props.confirmText}</div>
                    </div>
                </div>
            </div>
        </div>
    }
}