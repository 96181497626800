import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import Index from '../pages/index/index'
import IM from '../pages/im/index'
import IMUser from '../pages/im/user/index'
import IMList from '../pages/im/list/index'


const App = ({ history }) => (
    <Router history={history}>
        <Switch> 
            <Route exact path='/pages/index/index' component={Index} />
            <Route exact path='/' component={Index} />
            <Route exact path='/pages/im/index' component={IM} />
            <Route exact path='/pages/im/user/index' component={IMUser} />
            <Route exact path='/pages/im/list/index' component={IMList} />
        </Switch>
    </Router>
);
App.propTypes = {
    history: PropTypes.shape({}).isRequired
};
export default App;