import  http  from '@utils/http'

// eslint-disable-next-line
export default {
    // 全局
    defaults: {
        // 获取oss配置
        aliToken(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-ali-token',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        }
    },    
    // Im
    chat: {
        // 获取聊天用户列表
        getChatUserList(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-chat-user-list',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取聊天用户数据
        getChatUser(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-chat-user',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取聊天历史记录
        getChatRecordList(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-chat-record-list',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取收藏列表
        goodsCollectList(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/goods-collect-list',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取足迹列表
        getProductLogList(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-product-log-list',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取商品详情
        getProduceInfo(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-product-info',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取订单列表
        getOrderLit(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-order-list',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取订单详情
        getOrderInfo(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-order-info',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取快捷回复列表
        getFastReplyList(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-fast-reply-list',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取机器人回复列表
        getChannelReplyList(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-channel-reply-list',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        },
        // 获取快捷回复标签
        getChannelReplyTagList(params){
            return new Promise((resolve, reject) => {
                http("get",'/chat/index/get-channel-reply-tag-list',params).then(res => {
                    resolve (res);
                },error => {
                    console.log("网络异常~",error);
                    reject(error)
                })
            }) 
        }
    }  
}
