import React, { Component } from 'react';
import GoodsTemp from '@components/im/goods/goods'
import Empty from '@components/empty/empty'
import Loading from '@components/public/loading/loading'
import https from '@utils/api.js'
import './list.scss'
let self = null
export default class GoodsList extends Component {
    constructor (props) {
      super(...arguments)
      this.state = {
        tabs: [
          {
            value: '1',
            name: '我的订单',
          },
          {
            value: '2',
            name: '我的足迹',
          },
          {
            value: '3',
            name: '我的收藏',
          }
        ],
        tabPick: String(props.type)==='2'?'2':'1',
        list: [],
        showData: false,
        page: '1',
        pageSize: '20',
        isLoading: false
      }
    }
    static defaultProps = {
      type: '1'
    }
    componentWillMount () {
        self = this
        if(self.props.type===2){
          self.getProductLogList()
        }else{
          self.getOrderLit()
        }
    }
    changeTab(item,e){
      e.stopPropagation()
      if(item.value !== self.state.tabPick){
        self.setState({
          tabPick: item.value,
          page: '1',
          list: [],
          showData: false
        })
        if(item.value === '2'){
          self.getProductLogList()
        }else if(item.value === '3'){
          self.goodsCollectList()
        }else{
          self.getOrderLit()
        }
      }
    }
    // 获取订单列表
    getOrderLit(){
      self.setState({
        isLoading: true
      })
      https.chat.getOrderLit({
        pageNum: self.state.page,
        pageSize: self.state.pageSize
      }).then(res => {
        if(res.errorCode===0){
          self.setState({
            list: res.data.list,
            showData: true
          })
        }
        self.setState({
          isLoading: false
        })
      }).catch(error => {
        self.setState({
          isLoading: false
        })
          if (error.status === 299) {
              // common.linkLogin()
          }
      })
    }
    // 获取足迹列表
    getProductLogList(){
      self.setState({
        isLoading: true
      })
      https.chat.getProductLogList({
        pageNum: self.state.page,
        pageSize: self.state.pageSize
      }).then(res => {
        if(res.errorCode===0){
          self.setState({
            list: res.data.list,
            showData: true
          })
        }
        self.setState({
          isLoading: false
        })
      }).catch(error => {
        self.setState({
          isLoading: false
        })
          if (error.status === 299) {
              // common.linkLogin()
          }
      })
    }
    // 获取收藏列表
    goodsCollectList(){
      self.setState({
        isLoading: true
      })
      https.chat.goodsCollectList({
          pageNum: self.state.page,
          pageSize: self.state.pageSize
      }).then(res => {
          if(res.errorCode===0){
            self.setState({
              list: res.data.list,
              showData: true
            })
          }
          self.setState({
            isLoading: false
          })
      }).catch(error => {
          self.setState({
            isLoading: false
          })
          if (error.status === 299) {
              // common.linkLogin()
          }
      })
    }
    cancelDialog(){
      self.props.cancel&&self.props.cancel()
    }
    getListId(item){
      self.props.getListId&&self.props.getListId({
        data: item,
        type: self.state.tabPick
      })
    }

    render() {
      return (
        <div className='im_goods_dg'>
          {this.state.isLoading?<Loading />:null}
            <div className='shadow' onClick={self.cancelDialog.bind(this)}></div>
            <div className='im_goods_dg_main'>
                <div className='list_type'>
                    {
                        self.state.tabs.map((item,index)=>{
                            return <div className={item.value===self.state.tabPick?'type_item active':'type_item'} key={index} onClick={self.changeTab.bind(this,item)}>{item.name}</div>
                        })
                    }
                </div>
                <div className='list_scroll'>
                  {
                    self.state.showData?<React.Fragment>
                      {
                        self.state.list&&self.state.list.length>0
                        ? <React.Fragment>
                          {
                            self.state.list.map((item,index)=>{
                              return <div key={index} className='list_item' onClick={this.getListId.bind(this,item)}><GoodsTemp json={item} type={self.state.tabPick} /></div>
                            })
                          }
                        </React.Fragment>
                        : <div className='empty_list'>
                          {!self.state.isLoading?<Empty defaultText='哦呦~这里啥都没有' type='common' />:null}
                        </div>
                      }
                    </React.Fragment>:null
                  }
                </div>
            </div>
        </div>
      )
    }
}















