const defaultState = {
    cid: '',
    defaultCityConfig: {},
    cityList: [],
    cityDialogStatus: false
}
// eslint-disable-next-line
export default (state = defaultState,action)=>{
    // reducer里只能是纯函数 只能接受state 不能修改state 不能请求ajax

    return state
}