import React, { useState } from 'react'
import Rating from '@components/public/rating/rating'
import CancelIcon from '@assets/images/im-icon-close.png'
import './comment.scss'

function Comment(props) {
    const {commentParams,cancel} = props
    const [score, setScore] = useState(0)
    const [solve, setsolve] = useState(0)
    // const [score, setScore] = useState(0)
    let changeScore = (item)=>{
        setScore(item)
        // commentParams(item)
    }
    let cancelComment = ()=>{
        cancel()
    }
    let changeAnswer = (status,e)=>{
        e.stopPropagation()
        setsolve(status)
    }
    let submit = (e)=>{
        e.stopPropagation()
        if(score>0){
            commentParams({
                score: score,
                solve: solve
            })
        }
    }
    return (
        <div className='im_component_comment'>
            <div className='shadow' onClick={cancelComment.bind(this)}></div>
            <div className='component_comment'>
                <div className='comment_title'>
                    请对本次服务进行评价
                    <div className='cancel_btn' onClick={cancelComment.bind(this)}>
                        <img alt='' src={CancelIcon} className='cancel_icon' />
                    </div>
                </div>
                <div className='comment_body'>
                    <div className='comment_rating'>
                        <Rating score={score} changeScore={changeScore.bind(this)} />
                    </div>
                    <div className='comment_answer'>
                        <div className='answer_th'>本次问题（非必填）</div>
                        <div className='answer_btn'>
                            <div className={solve===1?'btn active':'btn'} onClick={changeAnswer.bind(this,1)}>未解决</div>
                            <div className={solve===2?'btn active':'btn'} onClick={changeAnswer.bind(this,2)}>已解决</div>
                        </div>
                        <div className={(score>0)?'submit_btn':'submit_btn disabled'} onClick={submit.bind(this)}>提交</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Comment