import React,{Component} from 'react'
import Loading from '@components/public/loading/loading'
import PhoneIcon from '@assets/images/image-phone.png'
import './index.scss'
import common from '@utils/common.js'
import https from '@utils/api.js'


let self = null
class ImUser extends Component {
    constructor () {
        super(...arguments)
        this.state = {
            uid: '',
            tuid: '',
            source: '',
            showData: false,
            userInfo: {},
            isLoading: false
        }
    }
    componentWillReceiveProps (nextProps) {
      
    }

    componentWillMount () {
        
    }
  
    componentDidMount () { 
        self = this
        let {uid,tuid,source} = self.state
        uid = common.getQueryVariable('uid')?common.getQueryVariable('uid'):uid
        tuid = common.getQueryVariable('tuid')?common.getQueryVariable('tuid'):tuid
        source = common.getQueryVariable('source')?common.getQueryVariable('source'):source
        self.setState({ uid,tuid,source },()=>{
            self.getData()
        })
    }
  
    componentWillUnmount () { 
        
    }
  
    componentDidShow () {
       
    }
  
    componentDidHide () {
        
    
    }

    getData(){
        self.setState({
            isLoading: true
        })
        https.chat.getChatUser({
            fromUid: self.state.uid,
            toUid: self.state.tuid,
            sourceType: self.state.source
        }).then(res => {
            if(res.errorCode===0){
                self.setState({
                    userInfo: res.data.toUser
                },()=>{
                    self.setState({
                        showData: true
                    })
                })
            }
            self.setState({
                isLoading: false
            })
        }).catch(error => {
            self.setState({
                isLoading: false
            })
        })
    }
    call(){
        common.call(self.state.userInfo.phone)
    }
    
    render () {
        return ( 
            <div className='im_user_info'>
                {this.state.isLoading?<Loading />:null}
                {
                    this.state.showData
                    ? <React.Fragment>
                        <div className='im_user_top flex_box'>
                            <img src={this.state.userInfo.avatar} className='avatar' alt='' />
                            <div className='user_info fb1'>
                                <div className='user_name ellipsis_1'>{this.state.userInfo.nickName}</div>
                                <div className='user_uid'>ID：{this.state.userInfo.uid}</div>
                            </div>
                        </div>
                        {
                            this.state.userInfo.phone?<div className='user_info_item flex_box' onClick={this.call}>
                                <div className='fb1 item_name'>手机号</div>
                                <div className='item_content'>
                                    {this.state.userInfo.phone}
                                    <img src={PhoneIcon} alt='' className='item_icon' />
                                </div>
                            </div>:null
                        }
                    </React.Fragment>
                    : null
                }
            </div>
        )
    }
}

export default ImUser;
