import React,{Component} from 'react'
import './index.scss'

let self = null
class Index extends Component {
    constructor () {
        super(...arguments)
        this.state = {

        }
    }
    componentWillReceiveProps (nextProps) {
      
    }

    componentWillMount () {

    }
  
    componentDidMount () { 
        self = this
    }
  
    componentWillUnmount () { 
        
    }
  
    componentDidShow () {

    }
  
    componentDidHide () {
        
    }

    linkIm(){
        self.props.history.push({ pathname: "/pages/im/index", search:'?tuid=305&uid=3&source=1&port=h5&token=3|c79f45a384246418c4437f8656db9919'})
    }
    
    render () {
        
        return ( 
            <div>
                index
                <div className='' onClick={this.linkIm}>去客服</div>
            </div>
        )
    }
}

export default Index;
