import React from 'react'
import './goods.scss'

// let self = null
function GoodsList(props) {
  const {json,type} = props
  let priceFun = ()=>{
    let module = null
    if(type==='chat'){
      module = <div className='fb1 goods_row_info'>
          <div className='name ellipsis_2'>
            {
              String(json.goods_property) === '1'?<span className='span_wx'>微信</span>:null
            }
            {
              String(json.goods_property) === '3'?<span className='span_dy'>抖音</span>:null
            }
            {json.goods_name}
          </div>
          <div className='price'><span className='text'>￥</span>{json.goods_money}</div>
      </div>
      
    }else{
      module = <div className='fb1 goods_row_info'>
          <div className='name ellipsis_2'>{json.name}</div>
          <div className='price'><span className='text'>￥</span>{String(type)==='1'?json.total_cash:json.sell_price}</div>
      </div>
    }
    return module
  }
  let goodsFun = ()=>{
    let module = null
    if(type==='chat'&&json.order_number){
      module = <div className='im_order_row'>
        <div className='order_id'>订单编号：{json.order_number}</div>
        <div className='im_goods_row flex_box'>
          <img alt='' src={json.first_pic} className='first_pic' />
          {priceFun()}
        </div>
      </div>
    }else{
      module = <div className='im_goods_row flex_box'>
          <img alt='' src={json.first_pic} className='first_pic' />
          {priceFun()}
      </div>
    }
    return module
  }
  return (
    <React.Fragment>
      {goodsFun()}
    </React.Fragment>
  )
}
export default GoodsList