// eslint-disable-next-line import/no-anonymous-default-export
export default {
    setCookie(cookName, cookValue, expireHours){
        var CookieStr = cookName + "=" + escape(cookValue);
        //不设定过期时间，浏览器关闭时，Cookie自动消失
        if(expireHours > 0){      
            var date = new Date();
            var ms = expireHours * 3600 * 1000;
            date.setTime(date.getTime() + ms);
            CookieStr += "; expires=" + date.toGMTString();
        }
        document.cookie = CookieStr;
        // setCookie("myCookie","menu1",2);
    },
    getCookie(cookName){
        var cookieStr = document.cookie.split(";")
        for (var i = 0; i < cookieStr.length; i++){
            var val = cookieStr[i].split("=")
            if (escape(cookName) === val[0])
            return unescape(val[1]);
        }
        return null;
        // getCookie("myCookie");
    },
    delCookie(cookName){
        var date = new Date()
        date.setTime(date.getTime() - 10000)
        var val = this.getCookie(cookName)
        if(val != null)
        document.cookie= cookName + "=;expires=" + date.toGMTString()
        // delCookie("myCookie");
    },
    // 截取url参数
    getQueryVariable(variable){
        let query = window.location.search.substring(1)
        let vars = query.split("&")
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=")
            if(pair[0] === variable){
                return pair[1]
            }
        }
        return(false)
    },
    // 复制
    copy(value, cb) {
        // 动态创建 textarea 标签
        const textarea = document.createElement('textarea')
        // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
        textarea.readOnly = 'readonly'
        textarea.style.position = 'absolute'
        textarea.style.left = '-9999px'
        // 将要 copy 的值赋给 textarea 标签的 value 属性
        textarea.value = value
        // 将 textarea 插入到 body 中
        document.body.appendChild(textarea)
        // 选中值并复制
        textarea.select()
        textarea.setSelectionRange(0, textarea.value.length)
        document.execCommand('Copy')
        document.body.removeChild(textarea)
        if (cb && Object.prototype.toString.call(cb) === '[object Function]') {
          cb()
        }
    },
    // 打电话
    call(telphone,e){
        window.location.href = `tel:${telphone}`
    },  
    /*函数节流*/
    throttle(fn, interval) {
        var enterTime = 0;//触发的时间
        var gapTime = interval || 300 ;//间隔时间，如果interval不传，则默认300ms
        return function() {
        var context = this;
        var backTime = new Date();//第一次函数return即触发的时间
        if (backTime - enterTime > gapTime) {
            fn.call(context,arguments);
            enterTime = backTime;//赋值给第一次触发的时间，这样就保存了第二次触发的时间
        }
        };
    },
    /*函数防抖*/
    debounce(fn, interval) {
        var timer;
        var gapTime = interval || 200;//间隔时间，如果interval不传，则默认1000ms
        return function() {
        clearTimeout(timer);
        var context = this;
        var args = arguments;//保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
        timer = setTimeout(function() {
            fn.call(context,args);
        }, gapTime);
        };
    },
    // 用户临时key
    getUserKey(){
        function S4(){
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        let dj_kf_ukey = localStorage.getItem('dj_kf_ukey')
        if (!dj_kf_ukey||dj_kf_ukey==='undefined') {
            dj_kf_ukey = (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4())
            localStorage.setItem('dj_kf_ukey',dj_kf_ukey)
        }
        return dj_kf_ukey;
    },
     /*禁止微信分享*/
    hideWechatShare(){
        function onBridgeReady(){
            window.WeixinJSBridge.call("hideOptionMenu")
        }
        if (typeof window.WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false)
            }else if (document.attachEvent){
                document.attachEvent("WeixinJSBridgeReady", onBridgeReady)
                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady)
            }
        }else{
            onBridgeReady()
        }
        // wx.hideMenuItems({
        //   menuList: ["menuItem:share:timeline", "menuItem:copyUrl", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:weiboApp", "menuItem:favorite", "menuItem:share:facebook", "menuItem:share:QZone", "menuItem:editTag", "menuItem:delete", "menuItem:copyUrl", "menuItem:originPage", "menuItem:readMode", "menuItem:openWithQQBrowser", "menuItem:openWithSafari", "menuItem:share:email", "menuItem:share:brand"] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
        // });
    },
}