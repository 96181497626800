import React from 'react'
import LoadingBg1 from '@assets/images/loading_1.png'
import LoadingBg2 from '@assets/images/loading_2.png'
import './loading.scss'

function Loading(props) {
    return <div className='loading_animation'>
        <img className='loading_bg' src={LoadingBg1} alt='' />
        <div className='loading_dv'>
            <img className='loading_an' src={LoadingBg2} alt='' />
        </div>
    </div>
}

export default Loading