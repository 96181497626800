import React from 'react'
import EmptyImg1 from '@assets/images/none-01.png'
import './empty.scss'

function Empty(props) {
  const {defaultText,type} = props
  let divFun = ()=>{
    let module = null
    switch (type) {   
        case 'common':
            module = <div className='empty'>
                <img src={EmptyImg1} className='empty_image_1' alt='' />
                <div className='title_main'>{defaultText}</div>
            </div>
            break
        default:
            module = <div className='empty'>
                <img src={EmptyImg1} className='empty_image_1' alt='' />
                <div className='title_main'>哦呦～这里啥都没有</div>
            </div>
            break;    
    }
    return module
  }
  return (
    <div className='empty_components'>
        {divFun()}
    </div>
  )
}
export default Empty