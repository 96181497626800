import React,{Component} from 'react'
import Loading from '@components/public/loading/loading'
import SearchIcon from '@assets/images/icon-search.png'
import IconMeOff from '@assets/images/icon-me-off.png'
import IconMeOn from '@assets/images/icon-me-on.png'
import IconWorkerOff from '@assets/images/icon-worker-off.png'
import IconWorkerOn from '@assets/images/icon-worker-on.png'
import IconCloseIm from '@assets/images/im-close-im.png'
import IconCheck from '@assets/images/im-icon-check.png'
import Toast from '@components/layer/toast/index'
import './index.scss'
import common from '@utils/common.js'
import moment from 'moment'
// import https from '@utils/api.js'

let self = null
let Socket = null
let loadingMore = null
class ImUser extends Component {
    constructor () {
        super(...arguments)
        this.state = {
            uid: '',
            tuid: '',
            cid: '',
            pid: '',
            order_id: '',
            identity: '',
            source: '',
            port: '',
            token: '',
            showData: false,
            userInfo: {},
            isLoading: false,
            taskRemindInterval: null,
            isConnect: false,
            isWSError: false,
            page: 1,
            pageSize: 20,
            list: [],
            allList: [],
            showPage: {},
            canLoadMore: true,
            keyword: '',
            footer: [
                {
                    name: '我的',
                    value: 1,
                    iconOff: IconMeOff,
                    iconOn:IconMeOn
                },
                {
                    name: '同事',
                    value: 2,
                    iconOff: IconWorkerOff,
                    iconOn:IconWorkerOn
                }
            ],
            footerVal: 1,
            isCloseIm: false,
            isPickAll: false
        }
    }
    componentWillReceiveProps (nextProps) {
      
    }

    componentWillMount () {
        common.hideWechatShare()
    }
  
    componentDidMount () { 
        self = this
        // uid：发起人 tuid：对话人 cid：城市 pid：商品id  oid：订单id  identity：发起人是客服传1，其余不传 source：1多鲸2鲜柚
        // port：来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
        let {uid, tuid, cid, pid, order_id, identity,source,port,token} = self.state
        uid = common.getQueryVariable('uid')?common.getQueryVariable('uid'):uid
        tuid = common.getQueryVariable('tuid')?common.getQueryVariable('tuid') : tuid
        cid = common.getQueryVariable('cid')?common.getQueryVariable('cid') : cid
        pid = common.getQueryVariable('pid')?common.getQueryVariable('pid') : pid
        order_id = common.getQueryVariable('oid')?common.getQueryVariable('oid') : order_id 
        identity = common.getQueryVariable('identity')?common.getQueryVariable('identity') : identity
        source = common.getQueryVariable('source')?common.getQueryVariable('source') : source
        port = common.getQueryVariable('port')?common.getQueryVariable('port') : port
        token = common.getQueryVariable('token')?common.getQueryVariable('token') : token
        self.setState({
            uid,tuid,cid,pid,order_id,identity,source,port,token
        },()=>{
            self.init()
        })
        document.title = String(source)==='1'?'多鲸客服':'鲜柚客服'
        loadingMore = common.debounce(self.more,20)
        self.onReachBottom()
    }
  
    componentWillUnmount () { 
        self.clearSocketHeart()
        window.removeEventListener("scroll", loadingMore)
    }
  
    componentDidShow () {
       
    }
  
    componentDidHide () {
        Socket.close()
        self.clearSocketHeart()
    }

    init(){
        self.newSocket()
        // 心跳机制 定时向后端发数据
        self.state.taskRemindInterval = setInterval(() => {
            if(Socket){
                Socket.send(JSON.stringify({ "msgType": 0 }))
            }
        }, 1000*3)
    }
    // 新建websocket
    newSocket(){
        self.setState({
            isLoading: true
        })
        //    判断是否登录
        let nowUserKey = common.getUserKey() //生成一个本地缓存id，用于同步之前未登录时聊天数据
        self.setState({
            nowUserKey: nowUserKey
        })
        let url = 'wss://kefu.duojingshenghuo.com/socket'
        Socket = new WebSocket(url)
        Socket.onopen = ()=> {
            self.setState({
                isConnect: true,
                isWSError: false,
                canLoadMore: false
            },()=>{
                //连接成功开始注册
                let msgParams = {
                    Type: 999,//消息类型(字符类型：int)：消息类型(字符类型：int)：1-发送消息|2-用户、客服数据|3-获取聊天记录|4-获取聊天列表|999-注册|998-注册失败
                    Source: String(self.state.source),//来源(字符类型：string)：1-多鲸
                    Data: {
                        "user": self.state.identity?String(self.state.identity):'0', // 0:用户 1：客服
                        "uid": String(self.state.uid),//用户编号（字符类型：string）
                        "key": nowUserKey,//当前用户前端唯一标识（字符类型：string）
                        "content": "注册",//消息内容（字符类型：string）
                        "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                        "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                        "source": self.state.port?String(self.state.port):'',// 来源端 渠道分配（字符类型：string）
                    }
                }
                Socket.send(JSON.stringify(msgParams))
                console.log('连接建立成功')
                // 获取用户列表数据
                let userParams = {
                    Type: 4,
                    Source: String(self.state.source),
                    Data: {
                        "uid": String(self.state.uid),//用户编号（字符类型：string）
                        "pageNum": String(self.state.page),//当前页数（字符类型：string）
                        "pageSize": String(self.state.pageSize),//每页条数（字符类型：string）
                    }
                }
                Socket.send(JSON.stringify(userParams))
                
                self.setState({
                    isLoading: false
                })
            })
        }
        Socket.onmessage = (msg)=> {
            let data = JSON.parse(msg.data)
            console.log('接收消息',data)  //后端返回的数据，渲染页面
            if(data.type === 4){
                let {list,allList} = self.state
                if(data.api_data&&data.api_data.list&&data.api_data.list.length>0){
                    data.api_data.list.forEach((item,index)=>{
                        let odate = moment.unix(item.c_time).format("YYYY/MM/DD")
                        let ndate = moment.unix(new Date().getTime()/1000).format("YYYY/MM/DD")
                        if(((new Date().getTime()/1000) - item.c_time)>24*3600||odate!==ndate){
                            item.c_time_moment = moment.unix(item.c_time).format("YYYY/MM/DD HH:mm")
                        }else{
                            item.c_time_moment = moment.unix(item.c_time).format("HH:mm")
                        }
                        item.isCheck = false
                    })
                    list = list.concat(data.api_data.list)
                    allList = list.concat(data.api_data.list)
                }
                self.setState({
                    list,
                    allList,
                    showPage: {
                        count: data.api_data.count,
                        page: data.api_data.page,
                        prepage: data.api_data.pageSize
                    },
                    showData: true,
                    isLoading: false
                })
                setTimeout(()=>{
                    self.setState({
                        canLoadMore: true,
                    })
                },2000)
            }
            if(data.type === 998){
                Socket.close() 
                self.setState({
                    isWSError: true
                })
                self.clearSocketHeart()
                Socket = null
            }
        }
        Socket.onclose = (msg)=> {
            console.log('断开连接',msg)
            self.setState({
                isConnect: false
            })
            self.clearSocketHeart()
        }
        Socket.onerror = ()=> {
            self.setState({
                isWSError: true
            })
            self.clearSocketHeart()
        }
    }
    clearSocketHeart(){
        if(self.state.taskRemindInterval){
            clearInterval(self.state.taskRemindInterval)
            self.setState({
                taskRemindInterval: null
            })
        }
    }
    // 上拉加载更多
    onReachBottom(){
        window.addEventListener('scroll', loadingMore)
    }
    more(){
        let clientHeight = document.body.clientHeight
        let availHeight = window.screen.availHeight
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
        if((clientHeight-availHeight-100)<=scrollTop){
            if ((self.state.showPage&&(self.state.allList.length <= (self.state.showPage.page * self.state.showPage.prepage)))||!self.state.canLoadMore) {
                return false
            }
            let {page} = self.state
            page = page + 1
            self.setState({
                page,
                canLoadMore: false
            },()=>{
                // 获取用户列表数据
                let userParams = {
                    Type: 4,
                    Source: String(self.state.source),
                    Data: {
                        "uid": String(self.state.uid),//用户编号（字符类型：string）
                        "pageNum": String(page),//当前页数（字符类型：string）
                        "pageSize": String(self.state.pageSize),//每页条数（字符类型：string）
                    }
                }
                if(self.state.footerVal===2){
                    userParams = {
                        Type: 4,
                        Source: String(self.state.source),
                        Data: {
                            "uid": String(self.state.uid),//用户编号（字符类型：string）
                            "diffUid": String(self.state.uid),//用户编号 获取同事聊天列表（字符类型：string）
                            "pageNum": String(page),//当前页数（字符类型：string）
                            "pageSize": String(self.state.pageSize),//每页条数（字符类型：string）
                        }
                    }
                }
                Socket.send(JSON.stringify(userParams))
            })
        }
    }
    linkChat(item){
        let search = '?tuid='+item.to_uid+'&uid='+self.state.uid+'&port='+self.state.port+'&identity=1&source='+self.state.source+'&token='+self.state.token+'&hFromUid='+item.from_uid
        self.props.history.push({ pathname: "/pages/im/index", search: search})
    }
    getInput(e){
        console.log(e.target.value)
        let {keyword,list,allList} = self.state
        keyword = e.target.value
        // 筛选
        let filterData = allList.filter( item => {
            let isOfficerType = keyword.length > 0 && item.nickName.indexOf(keyword) === -1 ? false : true
            return isOfficerType
        })
        // 去重
        filterData = filterData.filter((x, index, that) => that.indexOf(x) === index)
        list = filterData
        self.setState({
            keyword: keyword,
            list
        })
    }
    changeFooter(val){
        if(val === self.state.footerVal){
            return false
        }
        self.setState({
            isLoading: true
        })
        let {footerVal,page,list,allList} = self.state
        footerVal = val
        page = 1
        list = []
        allList = []
        // 获取用户列表数据
        let userParams = {
            Type: 4,
            Source: String(self.state.source),
            Data: {
                "uid": String(self.state.uid),//用户编号（字符类型：string）
                "pageNum": String(page),//当前页数（字符类型：string）
                "pageSize": String(self.state.pageSize),//每页条数（字符类型：string）
            }
        }
        if(val === 2){
            userParams = {
                Type: 4,
                Source: String(self.state.source),
                Data: {
                    "uid": String(self.state.uid),//用户编号（字符类型：string）
                    "diffUid": String(self.state.uid),//用户编号 获取同事聊天列表（字符类型：string）
                    "pageNum": String(page),//当前页数（字符类型：string）
                    "pageSize": String(self.state.pageSize),//每页条数（字符类型：string）
                }
            }
        }
        Socket.send(JSON.stringify(userParams))
        self.setState({
            footerVal,page,list,allList
        })
    }
    closeIm(){
        self.setState({
            isCloseIm: true
        })
    }
    cancelClose(){
        self.setState({
            isCloseIm: false
        })
    }
    changeCheck(index){
        let {list} = self.state
        list[index].isCheck = !list[index].isCheck
        self.setState({list})
    }
    closeAll(){
        let {list,isPickAll} = self.state
        if(isPickAll){
            list.forEach((item)=>{
                item.isCheck = false
            })
            isPickAll = false
        }else{
            list.forEach((item)=>{
                item.isCheck = true
            })
            isPickAll = true
        }
        self.setState({list,isPickAll})
    }
    // 结束对话
    offCheck(){
        let {list} = self.state
        let uids = []
        list.forEach((item,index)=>{
            if(item.isCheck){
                uids.push(item.to_uid)
            }
        })
        if(uids.length>0){
            uids.forEach((v1)=>{
                list.forEach((v2,i2)=>{
                    if(v2.to_uid ===v1){
                        list.splice(i2,1)
                    }
                })
            })
        }else{
            Toast.info('请先选择要关闭的聊天框',3000)
            return false
        }
        // console.log(list,uids)
        let msgData = {
            Type: 9,
            Source: String(self.state.source),
            Data: {
                "uid": String(self.state.uid),//用户编号（字符类型：string）
            },
            api_data: {
              'delUid': uids
            }
        }
        Socket.send(JSON.stringify(msgData))
        self.setState({
            isCloseIm: false,
        })
    }
    reload(){
        window.location.reload()
    }

    render () {
        return ( 
            <div className='im_chat_list'>
                {this.state.isLoading?<Loading />:null}
                {
                    this.state.showData
                    ? <React.Fragment>
                        { 
                            self.state.isWSError
                            ?<div className='connect_warn' onClick={this.reload.bind(this)}>阿偶，客服走丢了，请稍后刷新...<span className='reload_btn'>刷新</span></div>
                            :null
                        }
                        { 
                            !self.state.isConnect
                            ?<div className='connect_warn' onClick={this.reload.bind(this)}>阿偶，消息连接断开了，正在努力重连中...<span className='reload_btn'>刷新</span></div>
                            :null
                        }
                        <div className='search_bar flex_box'>
                            <div className='flex_box search_box'>
                                <img alt='' src={SearchIcon} className='search_icon' />
                                <input type='text' placeholder='搜索' className='search_input fb1' value={this.state.keyword} onChange={(e)=>this.getInput(e)} />
                            </div>
                            <div className='close_im' onClick={this.closeIm}>
                                <img alt='' src={IconCloseIm} className='icon_close_im' />
                            </div>
                        </div>
                        <div className='chat_list'>
                            {
                                this.state.list.map((item,index)=>{
                                    return <div className={item.is_up?'chat_li flex_box set_up':'chat_li flex_box'} key={index} onClick={this.linkChat.bind(this,item)}>
                                        <div className='user_img'>
                                            <img alt='' src={item.avatar} className='img' />
                                            {
                                                item.notLook>0?<span className='not_look'>{item.notLook}</span>:null
                                            }
                                        </div>
                                        <div className='user_info fb1'>
                                            <div className='user_name'>
                                                <div className='nick_name ellipsis_1'>{item.nickName}</div>
                                                <div className='chat_time'>{item.c_time_moment}</div>
                                            </div>    
                                            <div className='user_msg ellipsis_1'>{item.last_msg}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className='chat_list_footer flex_average'>
                            {
                                this.state.footer.map((item,index)=>{
                                    return <div className={this.state.footerVal===item.value?'footer_item active':'footer_item'} key={index} onClick={this.changeFooter.bind(this,item.value)}>
                                        {
                                            this.state.footerVal===item.value
                                            ?<img alt='' src={item.iconOn} className='icon' />
                                            :<img alt='' src={item.iconOff} className='icon' />
                                        }
                                        {item.name}
                                    </div>
                                })
                            }
                        </div>
                        {
                            this.state.isCloseIm
                            ? <div className='close_dialog'>
                                <div className='close_list close_scroll'>
                                    {
                                        this.state.list.map((item,index)=>{
                                            return <div className={item.is_up?'chat_li flex_box set_up':'chat_li flex_box'} key={index} onClick={this.changeCheck.bind(this,index)}>
                                                {
                                                    item.isCheck
                                                    ? <div className='check_close checked'>
                                                        <img alt='' src={IconCheck} className='check_icon' />
                                                    </div>
                                                    : <div className='check_close'></div>
                                                }
                                                <div className='user_img'>
                                                    <img alt='' src={item.avatar} className='img' />
                                                </div>
                                                <div className='user_info fb1'>
                                                    <div className='user_name'>
                                                        <div className='nick_name ellipsis_1'>{item.nickName}</div>
                                                    </div>    
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className='close_footer flex_average'>
                                    <div className='close_btn_1' onClick={this.cancelClose}>取消</div>
                                    <div className='close_btn_2' onClick={this.closeAll}>{!this.state.isPickAll?'全选':'反选'}</div>
                                    <div className='close_btn_3' onClick={this.offCheck}>结束对话</div>
                                </div>
                            </div>
                            : null
                        }
                    </React.Fragment>
                    : null
                }
            </div>
        )
    }
}

export default ImUser;
