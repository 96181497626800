import React, { useState } from 'react'
import Rating_1_1 from '@assets/images/im-icon-star-gary.png'
import Rating_1_2 from '@assets/images/im-icon-star-red.png'
import './rating.scss'

const Rating = props => {
    // 子像父传值
    const {score} = props
    const { changeScore } = props
    const [scoreText, setScoreText] = useState('')
    const status = [
        {
            value: 1,
            name: '非常不满意'
        },
        {
            value: 2,
            name: '不满意'
        },
        {
            value: 3,
            name: '一般'
        },
        {
            value: 4,
            name: '满意'
        },
        {
            value: 5,
            name: '非常满意'
        }
    ]
    let storeStar = (index)=>{
        let module = null
        if(index<score&&(score-index!==0.5)){
            module = <img alt='' src={Rating_1_2} className='icon' />
        }else if(index<score&&(score-index===0.5)){
            module = <img alt='' src={Rating_1_2} className='icon' />
        }else{
            module = <img alt='' src={Rating_1_1} className='icon' />
        }
        return module
    }
    let RatingFun = ()=>{
            let module = null
            module = (
                <div className='rating_star_1'>
                    <div className='rating_style_1'>
                        {
                            status.map((item,index)=>{
                                return <div className='star' key={index} onClick={getScore.bind(this,item)}>
                                    {storeStar(index)}
                                </div>
                            })
                        }
                    </div>
                    <div className='rating_text'>{scoreText}</div>
                </div>
            )
            return module
    }
    let getScore = (item,e)=>{
        e.stopPropagation()
        setScoreText(item.name)
        changeScore(item.value) 
    }

    return (
        <div>
            {RatingFun()}
        </div>
    );
};

export default Rating

