import React from 'react'
import EmojiJson from './emoji.json'
import './emoji.scss'

// let self = null
function Emoji(props) {
  const {getEmoji} = props
  let changeEmoji = (item,e)=>{
    e.stopPropagation()
    getEmoji(item)
  }
  let eobj = []
  for(let key in EmojiJson){
    eobj.push(EmojiJson[key])
  }
  return (
    <div className='im_component_empji'>
        {
          eobj.map((item,index)=>{
            // return <div key={index} title={item.title} className={'emoji_png '+item.name} onClick={changeEmoji.bind(this,item)} ></div>
            return <div key={index} title={item.title} className='emoji_png' onClick={changeEmoji.bind(this,item)} >{item.value}</div>
          })
        }
    </div>
  )
}
export default Emoji