import React from 'react'
// import { Toast } from 'antd-mobile-v5'
import { ImagePicker } from 'antd-mobile'
import EasyIcon from '@assets/images/im-icon-ofenlanguage.png'
import EmojiIcon from '@assets/images/im-icon-emoji.png'
import MoreIcon from '@assets/images/im-icon-more.png'
import CancelIcon from '@assets/images/im-icon-more-close.png'
import PhotoIcon from '@assets/images/im-icon-photo.png'
import IndentIcon from '@assets/images/im-icon-indent.png'
import GoodsIcon from '@assets/images/im-icon-goods.png'
import CopyIcon from '@assets/images/im-icon-copy.png'
import RetreatIcon from '@assets/images/im-icon-retreat.png'
import FailedIcon from '@assets/images/im-icon-failed.png'
import StarImg from '@assets/images/im-image-star.png'
import OrderImg from '@assets/images/im-image-order.png'
import GoodsImg from '@assets/images/im-image-goods.png'
import KefuImg from '@assets/images/im-icon-rengong.jpg'
import GuseeIcon from '@assets/images/icon-guess-1.png'
import ChangeIcon from '@assets/images/icon-change-1.png'
import Emoji from '@components/im/emoji/emoji'
import GoodsList from '@components/im/list/list'
import GoodsTemp from '@components/im/goods/goods'
import CommentTemp from '@components/im/comment/comment'
import Loading from '@components/public/loading/loading'
import ConfirmBox from '@components/layer/confirm_1/confirm_1'
import Toast from '@components/layer/toast/index'
import common from '@utils/common'
import https from '@utils/api.js'
import OSS from 'ali-oss'
import './index.scss'


let self = null
let Socket = null
let client = null
class IMIndex extends React.Component {
    state = {
        pageHeight: window.screen.availHeight,
        inputContent: '',
        inputValue: '',
        uid: '',
        nowUserKey: '',
        tuid: '',
        identity: '',
        hFromUid: '',
        token: '',
        taskRemindInterval: null,
        msgList: [],
        isConnect: false,
        showData: false,
        chatUsers: {},
        isShowTextModule: false,
        isShowMoreModule: false,
        files: [],
        multiple: true,
        source: '', // 1 多鲸 2 鲜柚
        port: '', // 来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string） 鲜柚：城市id
        ossToken: {},
        imgContent: '',
        isShowEmoji: false,
        hasMore: true,
        page: 1,
        pageSize: 20,
        historyData: {},
        isNoMore: true,
        typePick: '1',
        isShowGoods: false,
        isShowChatImg: false,
        chatImgUrl: '',
        isLoading: false,
        pid: '',
        order_id: '',
        productData: {},
        orderData: {},
        replyData: {},
        timeOutEvent: null,
        keFastType: 1,
        replyImgs: {},
        isReplyImgs: false,
        isReplyText: false,
        recallPickItem: {},
        recallPickIndex: '',
        isWSError: false, // 系统错误，服务端断开链接
        isComment: false,
        commentData: {},// 客服评价参数
        historyUserInfo: {}, //历史同事信息
        channelReplyPage: 1, // 机器人回复分页
        channelReplyData: [], // 机器人回复列表
        channelKefuData: {}, // 人工客服数据
        channelShowpage: {}, // 机器人回复列表分页数据
        channelSecondData: [], // 机器人回复二级菜单
        isLineUp: false, // 排队提示
        lineUpText: '', // 排队提示文案
        isCloseRoute: false,
        replyTagList: [], // 自动回复菜单
        replyTagLabel: '', // 自动回复筛选关键字
    };
    componentWillMount () {
        common.hideWechatShare()
        self = this
        let source = common.getQueryVariable('source')?common.getQueryVariable('source') : ''
        document.title = String(source)==='2'?'鲜柚客服':'多鲸客服'
    }
    componentDidMount() {
        // uid：发起人 tuid：对话人 cid：城市 pid：商品id  oid：订单id  identity：发起人是客服传1，其余不传 source：1多鲸2鲜柚
        // port：来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
        let {uid, tuid, cid, pid, order_id, identity,source,port,hFromUid,token} = self.state
        uid = common.getQueryVariable('uid')?common.getQueryVariable('uid'):uid
        tuid = common.getQueryVariable('tuid')?common.getQueryVariable('tuid') : tuid
        cid = common.getQueryVariable('cid')?common.getQueryVariable('cid') : cid
        pid = common.getQueryVariable('pid')?common.getQueryVariable('pid') : pid
        order_id = common.getQueryVariable('oid')?common.getQueryVariable('oid') : order_id 
        identity = common.getQueryVariable('identity')?common.getQueryVariable('identity') : identity
        source = common.getQueryVariable('source')?common.getQueryVariable('source') : source
        port = common.getQueryVariable('port')?common.getQueryVariable('port') : port
        hFromUid = common.getQueryVariable('hFromUid')?common.getQueryVariable('hFromUid') : hFromUid
        token = common.getQueryVariable('token')?common.getQueryVariable('token') : token
        self.setState({
            uid,tuid,cid,pid,order_id,identity,source,port,hFromUid,token
            // msgList: [{
            //     content: '[weixiao]您好，请问有什么可以帮您?',
            //     key: 'ee75753e-07d0-0a93-ef27-783dc32b464f',
            //     msgType: '1',
            //     source: 'h5',
            //     to_uid: '3',
            //     uid: '250'
            // }]
        },()=>{
            if(order_id&&order_id>0&&token){
                self.getOrderInfo()
            }else if(pid&&pid>0){
                self.getProduceInfo()
            }else{
                self.init()
            }
        })
    }
  
    componentWillUnmount() {
        self.clearSocketHeart()
    }
    componentDidShow () {
        
    }
  
    componentDidHide () {
        Socket.close()
        localStorage.removeItem('personStorage')
        self.clearSocketHeart()
    }
    init(){
        self.newSocket()
        self.getOssToken()
        self.getFastReplyList('')
        self.getChannelReplyList()
        self.getChannelReplyTagList()
        // 心跳机制 定时向后端发数据
        self.state.taskRemindInterval = setInterval(() => {
            if(Socket){
                Socket.send(JSON.stringify({ "msgType": 0 }))
            }
        }, 1000*3)
    }
    // 新建websocket
    newSocket(){
        self.setState({
            isLoading: true
        })
        //    判断是否登录
        let nowUserKey = common.getUserKey() //生成一个本地缓存id，用于同步之前未登录时聊天数据
        self.setState({
            nowUserKey: nowUserKey
        })
        // "proxy": "https://kefu.duojingshenghuo.com/"
        // "proxy": "http://192.168.1.105:8282/"
        // let url = 'ws://192.168.1.105:8282/socket'
        let url = 'wss://kefu.duojingshenghuo.com/socket'
        Socket = new WebSocket(url)
        Socket.onopen = ()=> {
            self.setState({
                isConnect: true,
                isWSError: false
            },()=>{
                //连接成功开始注册
                let msgParams = {
                    Type: 999,//消息类型(字符类型：int)：消息类型(字符类型：int)：1-发送消息|2-用户、客服数据|3-获取聊天记录|4-获取聊天列表|999-注册|998-注册失败
                    Source: String(self.state.source),//来源(字符类型：string)：1-多鲸
                    Data: {
                        "user": self.state.identity?String(self.state.identity):'0', // 0:用户 1：客服
                        "uid": String(self.state.uid),//用户编号（字符类型：string）
                        "key": nowUserKey,//当前用户前端唯一标识（字符类型：string）
                        "content": "注册",//消息内容（字符类型：string）
                        "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                        "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                        "source": self.state.port?String(self.state.port):'',// 来源端 渠道分配（字符类型：string）
                    }
                }
                Socket.send(JSON.stringify(msgParams))
                console.log('连接建立成功')
                // 获取历史消息(客服获取同事聊天记录)
                if(String(self.state.identity)==='1'&&self.state.hFromUid&&String(self.state.hFromUid)!==String(self.state.uid)){
                    // 获取用户数据
                    let userParams = {
                        Type: 2,
                        Source: String(self.state.source),
                        Data: {
                            "uid": String(self.state.hFromUid),//用户编号（字符类型：string）
                            "to_uid": String(self.state.tuid),//用户编号（字符类型：string）
                            "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                            "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                            "isHistory": '1',// 来源订单（字符类型：string）
                        }
                    }
                    Socket.send(JSON.stringify(userParams))
                    let historyMsgPrams = {
                        Type: 3,
                        Source: String(self.state.source),
                        Data: {
                            "uid": String(self.state.hFromUid),//用户编号（字符类型：string）
                            "to_uid": String(self.state.tuid),//用户编号（字符类型：string）
                            "pageNum": String(self.state.page), // 页码（字符类型：string）
                            "pageSize": String(self.state.pageSize), // 页总数码（字符类型：string）
                            "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                            "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                        }
                    }
                    Socket.send(JSON.stringify(historyMsgPrams))
                }
                // 获取用户数据
                let userParams = {
                    Type: 2,
                    Source: String(self.state.source),
                    Data: {
                        "uid": String(self.state.uid),//用户编号（字符类型：string）
                        "to_uid": String(self.state.tuid),//用户编号（字符类型：string）
                        "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                        "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                    }
                }
                Socket.send(JSON.stringify(userParams))
                // 获取历史消息
                let historyMsgPrams = {
                    Type: 3,
                    Source: String(self.state.source),
                    Data: {
                        "uid": String(self.state.uid),//用户编号（字符类型：string）
                        "to_uid": String(self.state.tuid),//用户编号（字符类型：string）
                        "pageNum": String(self.state.page), // 页码（字符类型：string）
                        "pageSize": String(self.state.pageSize), // 页总数码（字符类型：string）
                        "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                        "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                    }
                }
                Socket.send(JSON.stringify(historyMsgPrams))
                if(self.state.order_id&&self.state.order_id>0&&self.state.orderData.pid&&!self.state.identity){
                    let WSOrderData = localStorage.getItem('WSOrderData')
                    WSOrderData = WSOrderData&&WSOrderData!=='undefined'?JSON.parse(WSOrderData):''
                    let nowDate = new Date().getTime()
                    if(!WSOrderData||(WSOrderData&&(WSOrderData.order_id!==self.state.orderData.order_id||nowDate-WSOrderData.date>30*60*1000))){
                        // 发送订单
                        let arr = {
                            pid: String(self.state.orderData.pid),
                            name:self.state.orderData.name,
                            first_pic:self.state.orderData.first_pic,
                            order_id:  String(self.state.orderData.order_id),
                            money:  String(self.state.orderData.sell_price),
                            goods_property:  self.state.orderData.goods_property?String(self.state.orderData.goods_property):'1',
                        }
                        let msgData = {
                            Type: 1,//消息类型(字符类型：int)：1-发送消息|999-注册|998-注册失败
                            Source: String(self.state.source),//来源(字符类型：string)：1-多鲸
                            Data: self.getMsgData('3',arr)//消息内容
                        }
                        Socket.send(JSON.stringify(msgData))
                        localStorage.setItem('WSOrderData',JSON.stringify({
                            order_id:  self.state.orderData.order_id,
                            date: new Date().getTime()
                        }))
                    }
                    // self.send(3,arr)
                }else if(self.state.pid&&self.state.pid>0&&self.state.productData.pid&&!self.state.identity&&self.state.tuid!==0){
                    let WSGoodsData = localStorage.getItem('WSGoodsData')
                    WSGoodsData = WSGoodsData&&WSGoodsData!=='undefined'?JSON.parse(WSGoodsData):''
                    let nowDate = new Date().getTime()
                    if(!WSGoodsData||(WSGoodsData&&(WSGoodsData.pid!==self.state.productData.pid||nowDate-WSGoodsData.date>30*60*1000))){
                        // 发送商品
                        let arr = {
                            pid:  String(self.state.productData.pid),
                            name:self.state.productData.name,
                            first_pic:self.state.productData.first_pic,
                            order_id:  String(self.state.productData.order_id),
                            money:  String(self.state.productData.sell_price),
                            goods_property:  self.state.productData.goods_property?String(self.state.productData.goods_property):'1',
                        }
                        let msgData = {
                            Type: 1,//消息类型(字符类型：int)：1-发送消息|999-注册|998-注册失败
                            Source: String(self.state.source),//来源(字符类型：string)：1-多鲸
                            Data: self.getMsgData('3',arr)//消息内容
                        }
                        Socket.send(JSON.stringify(msgData))
                        localStorage.setItem('WSGoodsData',JSON.stringify({
                            pid:  self.state.productData.pid,
                            date: new Date().getTime()
                        }))
                    }
                }
                self.setState({
                    isLoading: false
                })
            })
        }
        Socket.onmessage = (msg)=> {
            let data = JSON.parse(msg.data)
                console.log('接收消息',data)  //后端返回的数据，渲染页面
                if(data.type===1||data.type===8){
                    // 1: 消息 8：评价提醒
                    self.newMsgList(data)
                }
                if(data.type===2){
                    if(data.isHistory){
                        self.setState({
                            historyUserInfo: data.api_data,
                        })
                    }else{
                        self.setState({
                            allUserInfo: data.api_data,
                            showData: true
                        },()=>{
                            let box = document.getElementById('imMsgs')
                            // 下拉加载更多
                            box.addEventListener('scroll', (e) => {
                                if(e.target.scrollTop<=0){
                                    if(self.state.moreDisabled){
                                        return false
                                    }
                                    if(self.state.historyData&&(self.state.historyData.count > (self.state.historyData.page * self.state.historyData.pageSize))){
                                        let {page} = self.state
                                        page = Number(page) + 1
                                        self.setState({
                                            page,
                                            moreDisabled: true
                                        },()=>{
                                            let historyMsgPrams = {
                                                Type: 3,
                                                Source: String(self.state.source),
                                                Data: {
                                                    "uid": self.state.uid,//用户编号（字符类型：string）
                                                    "to_uid": self.state.tuid,//用户编号（字符类型：string）
                                                    "pageNum": String(self.state.page), // 页码（字符类型：string）
                                                    "pageSize": String(self.state.pageSize), // 页总数码（字符类型：string）
                                                    "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                                                    "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                                                }
                                            }
                                            Socket.send(JSON.stringify(historyMsgPrams))
                                        })
                                    }
                                }
                            })
                        })
                    }
                }
                if(data.type===3){
                    let isNoMore = false
                    if(data.api_data){
                        let pageNum = parseInt(data.api_data.count / data.api_data.pageSize)
                        if(Number(pageNum)===Number(self.state.page-1)||(data.api_data.count <= data.api_data.pageSize)){
                            isNoMore = true
                        }
                    }
                    self.setState({
                        historyData: data.api_data,
                        isNoMore: isNoMore
                    })
                    if(data.api_data&&data.api_data.count>0){
                        self.oldMsgList(data.api_data.list)
                    }
                }
                if(data.type===5 && String(data.data.to_uid)!==String(self.state.tuid)){
                    self.setState({
                        tuid: data.data.to_uid,
                    },()=>{
                        // 获取用户数据
                        let userParams = {
                            Type: 2,
                            Source: String(self.state.source),
                            Data: {
                                "uid": String(self.state.uid),//用户编号（字符类型：string）
                                "to_uid": String(self.state.tuid),//用户编号（字符类型：string）
                                "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                                "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                            }
                        }
                        Socket.send(JSON.stringify(userParams))
                        // 获取历史消息
                        let historyMsgPrams = {
                            Type: 3,
                            Source: String(self.state.source),
                            Data: {
                                "uid": self.state.uid&&self.state.uid!=='undefined'?String(self.state.uid):nowUserKey,//用户编号（字符类型：string）
                                "to_uid": String(self.state.tuid),//用户编号（字符类型：string）
                                "pageNum": String(self.state.page), // 页码（字符类型：string）
                                "pageSize": String(self.state.pageSize), // 页总数码（字符类型：string）
                                "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                                "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                            }
                        }
                        Socket.send(JSON.stringify(historyMsgPrams))
                        // if(self.state.pid&&self.state.pid>0&&self.state.productData.pid&&!self.state.identity&&self.state.tuid!==0){
                        //     // 发送商品
                        //     let arr = {
                        //         pid:  String(self.state.productData.pid),
                        //         name:self.state.productData.name,
                        //         first_pic:self.state.productData.first_pic,
                        //         order_id:  String(self.state.productData.order_id),
                        //         money:  String(self.state.productData.sell_price)
                        //     }
                        //     let msgData = {
                        //         Type: 1,//消息类型(字符类型：int)：1-发送消息|999-注册|998-注册失败
                        //         Source: String(self.state.source),//来源(字符类型：string)：1-多鲸
                        //         Data: self.getMsgData('3',arr)//消息内容
                        //     }
                        //     Socket.send(JSON.stringify(msgData))
                        // }
                    })
                }
                self.setState({
                    isLineUp: false
                })
                if(data.type===13){
                    if(data.data.content){
                        self.setState({
                            isLineUp: true,
                            lineUpText: data.data.content
                        })
                    }else{
                        self.setState({
                            isLineUp: false,
                            lineUpText: ''
                        })
                    }
                }
        }
        Socket.onclose = (msg)=> {
            console.log('断开连接',msg)
            self.setState({
                isConnect: false
            })
            self.clearSocketHeart()
        }
        Socket.onerror = ()=> {
            console.log( '连接建立失败')
            self.setState({
                isWSError: true
            })
            self.clearSocketHeart()
        }
    }
    clearSocketHeart(){
        if(self.state.taskRemindInterval){
            clearInterval(self.state.taskRemindInterval)
            self.setState({
                taskRemindInterval: null
            })
        }
    }
    // 获取商品详情
    getProduceInfo(){
        self.setState({
            isLoading: true
        })
        https.chat.getProduceInfo({
            pid: self.state.pid
        }).then(res => {
            if(res.errorCode===0){
                self.setState({
                    productData: res.data
                },()=>{
                    self.init()
                })
            }else{
                self.setState({
                    isLoading: false
                })
                self.init()
            }
        }).catch(error => {
            self.setState({
                isLoading: false
            })
        })
    }
    // 获取订单详情
    getOrderInfo(){
        self.setState({
            isLoading: true
        })
        https.chat.getOrderInfo({
            order_id: self.state.order_id
        }).then(res => {
            if(res.errorCode===0){
                self.setState({
                    orderData: res.data
                },()=>{
                    self.init()
                })
            }else{
                self.setState({
                    isLoading: false
                })
                self.init()
            }
        }).catch(error => {
            self.setState({
                isLoading: false
            })
        })
    }
    getFastReplyList1(label,e){
        e.stopPropagation()
        self.getFastReplyList(label)
    }
    // 获取快捷回复列表
    getFastReplyList(label){
        self.setState({
            replyTagLabel: label?label:''
        })
        https.chat.getFastReplyList({
            label: label&&label!=='全部'?label:'', // 标签
            type: self.state.keFastType, // 1-快捷回复语|2-快捷回复图片
            kefuUid: self.state.identity?String(self.state.uid):'', // 客服界面获取客服快捷时带客服uid，用户聊天界面不传
            port: self.state.port, // 来源端：来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
            pageNum: 1,
            pageSize: 100
        }).then(res => {
            if(res.errorCode===0){
                if(self.state.keFastType === 1){
                    self.setState({
                        replyData: res.data,
                        isReplyText: true
                    })
                }else{
                    self.setState({
                        replyImgs: res.data,
                        isReplyImgs: true
                    })
                }
            }
        }).catch(error => {
            self.setState({
                isLoading: false
            })
        })
    }
    // 获取机器人回复列表
    getChannelReplyList(){
        https.chat.getChannelReplyList({
            type: self.state.keFastType, // 1-快捷回复语|2-快捷回复图片
            kefuUid: self.state.identity?String(self.state.uid):'', // 客服界面获取客服快捷时带客服uid，用户聊天界面不传
            port: self.state.port, // 来源端：来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
            pageNum: self.state.channelReplyPage,
            pageSize: 5
        }).then(res => {
            if(res.errorCode===0){
                let {msgList} = self.state
                let arr = {
                    msgType: "channel",
                }
                let arr1 = msgList.find((val)=>{return val.msgType==='channel'})
                if(arr1&&arr1.msgType){
                }else{
                    msgList.push(arr)
                }
                self.setState({
                    channelReplyData: res.data.list,
                    channelKefuData: res.data.kefu,
                    channelShowpage: res.data.showpage,
                    msgList
                })
            }
        }).catch(error => {
            self.setState({
                isLoading: false
            })
        })
    }
    // 获取快捷回复标签列表
    getChannelReplyTagList(){
        https.chat.getChannelReplyTagList({
            type: self.state.keFastType, // 1-快捷回复语|2-快捷回复图片
            kefuUid: self.state.identity?String(self.state.uid):'', // 客服界面获取客服快捷时带客服uid，用户聊天界面不传
            port: self.state.port, // 来源端：来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
            pageNum: 1,
            pageSize: 100,
            label: self.state.replyTagLabel
        }).then(res => {
            if(res.errorCode===0){
                if(res.data.list&&res.data.list.length>0){
                    let arr = {
                        label: '全部'
                    }
                    res.data.list.unshift(arr)
                }
                self.setState({
                    replyTagList: res.data.list
                })
            }
        }).catch(error => {
            self.setState({
                isLoading: false
            })
        })
    }
    // 信息展示模块
    msgFun(item,index){
        let module = null
        if(item.isDelete){
            if(String(item.uid)===String(self.state.uid)){
                module = <div className='chat_msg_recall' id={index+1}>你撤回了一条消息</div>
            }else{
                module = <div className='chat_msg_recall' id={index+1}>消息已被撤回</div>
            }
            return module
        }
        if(String(item.msgType) === '3'){
            module = <div className={item.isSendFailed?'chat_goods_temp failed':'chat_goods_temp'} id={index+1} onClick={this.linkGoods.bind(this,item.pid)}>
                {
                    item.isSendFailed?<img alt='' src={FailedIcon} className='faild_icon' />:null
                }
                <GoodsTemp json={item} type='chat' />
            </div>
        }else if(String(item.msgType) === 'channel'&&self.state.channelReplyData&&self.state.channelReplyData.length>0){
            module = <div className='channel_div'>
                <div className='channel_div_top'>
                    <div className='channel_div_t1'>
                        <img src={GuseeIcon} className='channel_div_i1' alt='' />
                        猜你想问
                    </div>
                    <div className='channel_div_t2' onClick={self.changeChannelPage}>
                        换一换
                        <img src={ChangeIcon} className='channel_div_i2' alt='' />
                    </div>
                </div>
                {
                    self.state.channelReplyData.map((item,index)=>{
                        return <div className='channel_div_li' key={index} onClick={self.changeChannel.bind(this,item)}>
                            {item.keyword}
                        </div>
                    })
                }
                {/* <div className='channel_div_li' onClick={self.changeKefu.bind(this)}>
                    {self.state.channelKefuData.title}
                </div> */}
            </div>
        }else if(String(item.msgType) === 'channelSecond'&&self.state.channelSecondData&&self.state.channelSecondData.length>0){
            module = <div className='channel_div'>
                <div className='channel_div_li_1'>
                    猜您还想咨询以下问题：
                </div>
                {
                    self.state.channelSecondData.map((item,index)=>{
                        return <div className='channel_div_li' key={index} onClick={self.changeChannel.bind(this,item)}>
                            {item.keyword}
                        </div>
                    })
                }
            </div>
        }else{
            if(String(item.msgType) === '6'&&String(item.uid)===String(self.state.uid)&&!self.state.identity){
                module = (<div className='chat_comment_temp'>
                    <div className='text'>您的评价已收到，很高兴为您服务</div>
                </div>)
            }else{
                if(!self.state.uid&&item.to_uid===self.state.nowUserKey){
                    module = (
                        <div id={index+1} className='chat_li clearfix other'>
                            <img alt='' src={self.state.allUserInfo.toUser.avatar} className='chat_avatar' onClick={self.viewUser.bind(this,item)} />
                            { self.contentFun(item) }
                        </div>
                    )
                }else if(String(item.uid)===String(self.state.uid)||(item.uid>0&&(String(item.uid)===String(self.state.hFromUid))&&String(self.state.identity==='1'))||(item.key===self.state.nowUserKey)){
                    module = (
                        <div id={index+1} className='chat_li clearfix self' onTouchStart={this._onTouchStart.bind(this,index)} onTouchEnd={this._onTouchEnd.bind(this,index)} onTouchMove={this._onTouchMove.bind(this,index)}>
                            {
                                self.state.hFromUid&&self.state.identity===1
                                ? <img alt='' src={self.state.historyUserInfo.fromUser.avatar} className='chat_avatar' />
                                : <img alt='' src={self.state.allUserInfo.fromUser.avatar} className='chat_avatar' />
                            }
                            { self.contentFun(item) }
                            {
                                item.isRecall?<div className='recall_bar'>
                                    {String(item.msgType)==='1'?<span className='recall_btn' onClick={this.copyMsg.bind(this,item,index)}><img src={CopyIcon} className='btn_icon' alt='' />复制</span>:null}
                                    <span className='recall_btn' onClick={this.recallMsg.bind(this,item,index)}><img src={RetreatIcon} className='btn_icon' alt='' />撤回</span>
                                </div>:null
                            }
                            {
                                item.isSendFailed?<img alt='' src={FailedIcon} className='faild_icon' />:null
                            }
                        </div>
                    )
                }else{
                    module = (
                        <div id={index+1} className='chat_li clearfix other'>
                            <img alt='' src={self.state.allUserInfo.toUser.avatar} className='chat_avatar' onClick={self.viewUser.bind(this,item)} />
                            { self.contentFun(item) }
                        </div>
                    )
                }
            }
        }
        return module
    }
    // 区分不同格式的信息
    contentFun(item){
        let module = null
        if(String(item.msgType) === '1'||String(item.msgType) === '6'){
            let reg = /\[(.*?)\]/gi
            let str = item.content
            let tmp = str.match(reg)
            if (tmp) {
                for (let i = 0; i < tmp.length; i++) {
                    // console.log(tmp[i]); // 保留中括号
                    // console.log(tmp[i].replace(reg, "$1")); // 不保留中括号
                    let html = `<span title=${tmp[i]} class='emoji_png ${tmp[i].replace(reg, "$1")}' ></span>`
                    str = str.replace(tmp[i], html )
                }
                module = (<div dangerouslySetInnerHTML={{__html: str}} className='chat_msg'></div>)
            }else{
                module = (<div className='chat_msg'>{item.content}</div>)
            }
        }
        if(String(item.msgType) === '2'){
            module = (<div className='chat_msg'>
                <img alt='' src={item.content} className='img' onClick={this.showChatImg.bind(this,item.content)} />
            </div>)
        }
        if(String(item.msgType) === '3'){
            module = (<div className='chat_msg'>
                <GoodsTemp json={item} type='chat' />
            </div>)
        }
        if(String(item.msgType) === '6'){
            module = (<div className='chat_msg'>{item.content}星评价</div>)
        }
        return module
    }
    // div选中
    getFocus(e){
        e.stopPropagation()
        self.showPerson()
        self.cancelDialog()
        self.setState({
            isShowEmoji: false
        })
        self.getContent()
    }
    // 获取div数据
    getContent(){
        let input = document.getElementById("imContent");
        input.addEventListener("keydown", function(event) {
            event.stopPropagation()
            let keycode = window.event ? event.keyCode : event.which
            let evt = event || window.event
            let inputTxt = input.innerHTML
            if(inputTxt!==''){
                // 回车-->发送消息
                if (keycode === 13 && !(evt.ctrlKey)) {
                    // 发送消息的代码
                    console.log('发送消息',inputTxt)
                    self.setState({
                        inputContent: inputTxt,
                        inputValue: inputTxt
                    },()=>{
                        self.send(1)
                    })
                    event.preventDefault()
                    return false;
                }
                // ctrl+回车-->换行
                // if (evt.ctrlKey && evt.keyCode === 13) {
                //     input.innerHTML = input.innerHTML + '<br/>'
                //     self.placeCaretAtEnd()
                //     return false
                // }
            }
        });
    }
    placeCaretAtEnd(el) {
        //设置输入焦点
        let o = document.getElementById("imContent").lastChild       
        let textbox = document.getElementById('imContent')
        let sel = window.getSelection()
        let range = document.createRange()
        range.selectNodeContents(textbox)
        range.collapse(false)
        range.setEndAfter(o)
        range.setStartAfter(o)
        sel.removeAllRanges()
        sel.addRange(range)
    }
    // 发送消息
    send(msgType,goodsData){
        if(self.state.isConnect){
            if(String(msgType)==='1'&&self.state.inputContent===''){
                return false
            }
            // console.log('商品数据',msgType,goodsData)
            let msgData = {
                Type: 1,//消息类型(字符类型：int)：1-发送消息|999-注册|998-注册失败
                Source: String(self.state.source),//来源(字符类型：string)：1-多鲸
                Data: self.getMsgData(msgType,goodsData)//消息内容
            }
            Socket.send(JSON.stringify(msgData,goodsData))
            if(document.getElementById('imContent')){
                document.getElementById('imContent').innerHTML = ''
                self.setState({
                    inputContent: ''
                })
            }
        }else{
            console.log('您已断开连接，请刷新重试')
            let {msgList} = self.state
            let obj = {
                content: '',
                key: self.state.nowUserKey,
                msgType: String(msgType),
                source: self.state.port,
                to_uid: String(self.state.tuid),
                uid: String(self.state.uid),
                isSendFailed: true
            }
            if(String(msgType==='1')){
                obj.content = self.state.inputContent
            }else if(String(msgType==='2')){
                obj.content = self.state.imgContent
            }else if(String(msgType==='3')){
                obj.order_sn = goodsData.order_id?String(goodsData.order_id):''
                obj.pid = goodsData.pid?String(goodsData.pid):''
                obj.goods_name = goodsData.name?goodsData.name:''
                obj.first_pic = goodsData.first_pic?goodsData.first_pic:''
                obj.goods_money = goodsData.money?String(goodsData.money):''
                obj.order_number = goodsData.order_number?goodsData.order_number:''
                obj.order_id = self.state.order_id?String(self.state.order_id):''
            }
            msgList.push(obj)
            self.setState({
                msgList
            },()=>{
                document.getElementById('imMsgs').scrollTo(0,document.getElementById("imMsgs").scrollHeight)
            })
        }
    }
    getMsgData(msgType,goodsData){
        switch (String(msgType)){
            case '1':
                return {
                    "msgType": String(msgType),//消息内容类型（字符类型：string）：1-文本|2-图片|3-商品|4-文件|5-转接客服
                    "uid": String(self.state.uid),//发送用户编号（字符类型：string）
                    "key": self.state.nowUserKey,//当前用户前端唯一标识（字符类型：string）
                    "content": String(self.state.inputContent),//消息内容（字符类型：string）
                    "to_uid": String(self.state.tuid),//接收用户uid（字符类型：string）
                    "source": self.state.port,//来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
                    "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                    "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                }
            case '2':  
                return {
                    "msgType": String(msgType),//消息内容类型（字符类型：string）：1-文本|2-图片|3-商品|4-文件|5-转接客服
                    "uid": String(self.state.uid),//发送用户编号（字符类型：string）
                    "key": self.state.nowUserKey,//当前用户前端唯一标识（字符类型：string）
                    "content": self.state.imgContent,//消息内容（字符类型：string）
                    "to_uid": String(self.state.tuid),//接收用户uid（字符类型：string）
                    "source": self.state.port,//来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
                    "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                    "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                }  
            case '3':  
                return {
                    "msgType": String(msgType),//消息内容类型（字符类型：string）：1-文本|2-图片|3-商品|4-文件|5-转接客服
                    "uid": String(self.state.uid),//发送用户编号（字符类型：string）
                    "key": self.state.nowUserKey,//当前用户前端唯一标识（字符类型：string）
                    "content": '',//消息内容（字符类型：string）
                    "to_uid": String(self.state.tuid),//接收用户uid（字符类型：string）
                    "source": self.state.port,//来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）,
                    "order_sn": goodsData.order_id?String(goodsData.order_id):'',//订单来源带订单号（字符类型：string）
                    "pid": goodsData.pid?String(goodsData.pid):'',//商品pid（字符类型：string）
                    "goods_name": goodsData.name?goodsData.name:'',//商品名称（字符类型：string）
                    "first_pic": goodsData.first_pic?goodsData.first_pic:'',//商品图片（字符类型：string）
                    "goods_money": goodsData.money?String(goodsData.money):'',//商品价格/支付金额（字符类型：string）
                    "order_number": goodsData.order_number?goodsData.order_number:'',//商品价格/支付金额（字符类型：string）
                    "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                    "goods_property": goodsData.goods_property?String(goodsData.goods_property):'1',// 来源订单（字符类型：string）
                } 
            case '5':  
                return {
                    "msgType": String(msgType),//消息内容类型（字符类型：string）：1-文本|2-图片|3-商品|4-文件|5-转接客服
                    "uid": String(self.state.uid),//发送用户编号（字符类型：string）
                    "key": self.state.nowUserKey,//当前用户前端唯一标识（字符类型：string）
                    "content": '转接',//消息内容（字符类型：string）
                    "to_uid": String(self.state.tuid),//接收用户uid（字符类型：string）
                    "source": self.state.port,//来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
                    "pid": self.state.pid?String(self.state.pid):'', // 来源商品（字符类型：string）
                    "order_id": self.state.order_id?String(self.state.order_id):'',// 来源订单（字符类型：string）
                }  
            case '6':  
                return {
                    "msgType": String(msgType),//消息内容类型（字符类型：string）：1-文本|2-图片|3-商品|4-文件|5-转接客服|6-客服评价
                    "uid": String(self.state.uid),//发送用户编号（字符类型：string）
                    "key": self.state.nowUserKey,//当前用户前端唯一标识（字符类型：string）
                    "content": String(self.state.commentData.content),//消息内容（字符类型：string）
                    "solve": String(self.state.commentData.solve),//消息内容（字符类型：string）
                    "to_uid": String(self.state.tuid),//接收用户uid（字符类型：string）
                    "source": self.state.port,//来源端：h5-微信H5|djsh-微信小程序|djmobile-浏览器|douyin-抖音小程序|kuaishou-快手,（字符类型：string）
                }  
            default:
                break    
        }
    }
    newMsgList(data){
        let {msgList} = self.state
        switch (String(data.type)){
            case '1':
                if((String(self.state.tuid) === String(data.data.uid)&&String(self.state.uid) === String(data.data.to_uid))||(data.data.to_uid===self.state.nowUserKey)){
                    // 对方发--显示数据  data.data.to_uid===self.state.nowUserKey(未登录角色判断)
                    msgList.push(data.data)
                }else if((String(self.state.tuid) === String(data.data.to_uid)&&String(self.state.uid) === String(data.data.uid))||(data.data.key===self.state.nowUserKey)){
                    // 自己发--显示数据 data.data.key===self.state.nowUserKey(未登录角色判断)
                    msgList.push(data.data)
                }
                console.log('消息队列',msgList)
                self.setState({msgList},()=>{
                    // if(msgList.length>10){
                        let timer1 = setTimeout(()=>{
                            document.getElementById('imMsgs').scrollTo(0,document.getElementById("imMsgs").scrollHeight)
                            clearTimeout(timer1)
                        },300)
                    // }
                })
                break   
            case '8':
                if(data.data&&data.data.content){
                    msgList.push(data.data)
                    // console.log('消息队列',msgList)
                    self.setState({msgList},()=>{
                        if(msgList.length>10){
                            let timer1 = setTimeout(()=>{
                                document.getElementById('imMsgs').scrollTo(0,document.getElementById("imMsgs").scrollHeight)
                                clearTimeout(timer1)
                            },300)
                        }
                    })
                }
                break       
            case '998':
                Socket.close() 
                self.setState({
                    isWSError: true
                })
                self.clearSocketHeart()
                Socket = null
                break  
            case '999':
                // 注册成功
                break 
            default:
                break    
        }
    }
    oldMsgList(data){
        let {msgList,page} = self.state
        let arr = []
        if(data&&data.length>0){
            data.forEach((val)=>{
                val.msgData.id = val.id
                val.msgData.isDelete = val.isDelete
                if(page===1){
                    msgList.push(val.msgData)
                }else{
                    arr.push(val.msgData)
                }
            })
            if(page>1){
                msgList = arr.concat(msgList)
            }
        }
        self.setState({
            msgList
        },()=>{
            setTimeout(()=>{
                self.setState({
                    moreDisabled: false
                })
            },1000)
            if(page>1&&data&&data.length>0){
                let n = data&&data.length>0?data.length:''
                window.location.href = '#'+ n
            }
            if(page===1&&msgList.length>10){
                let timer2 = setTimeout(()=>{
                    if(document.getElementById('imMsgs')){
                        document.getElementById('imMsgs').scrollTo(0,document.getElementById("imMsgs").scrollHeight)
                    }
                    clearTimeout(timer2)
                },300)
                let timer3 = setTimeout(()=>{
                    if(document.getElementById('imMsgs')){
                        document.getElementById('imMsgs').scrollTo(0,document.getElementById("imMsgs").scrollHeight)
                    }
                    clearTimeout(timer3)
                },800)
            }
        })
    }
    showTextModule(e){
        e.stopPropagation()
        if(this.state.identity){
            let {isShowTextModule} = self.state
            if(self.state.isShowTextModule){
                isShowTextModule = false
            }else{
                isShowTextModule = true
            }
            self.setState({
                isShowTextModule,
                isShowMoreModule: false,
                isShowEmoji: false,
                isShowGoods: false,
            })
        }else{
            self.showPerson()
        }
    }
    cancelTextModule(e){
        e.stopPropagation()
        self.setState({
            isShowTextModule: false
        })
    }
    showMoreModule(e){
        e.stopPropagation()
        self.showPerson()
        self.setState({
            isShowMoreModule: true,
            isShowEmoji: false,
            isShowGoods: false,
            isShowTextModule: false
        })
    }
    cancelMoreModule(e){
        e.stopPropagation()
        self.setState({
            isShowMoreModule: false
        })
    }
    // 快捷聊天
    pickText(item,e){
        e.stopPropagation()
        document.getElementById('imContent').innerHTML = item.message
        self.setState({
            inputContent: item.message,
            isShowTextModule: false
        },()=>{
            self.send(1)
        })
    }
    // 
    pickFastImg(item,e){
        e.stopPropagation()
        self.setState({
            imgContent: item.message,
            isShowTextModule: false
        },()=>{
            self.send(2)
        })
    }
    // oss参数
    getOssToken(){
        https.defaults.aliToken().then(res => {
            if(res.errorCode===0){
                self.setState({
                    ossToken: res.data
                })
                client = new OSS({   
                    accessKeyId: res.data.accessKeyId, 
                    accessKeySecret: res.data.accessKeySecret,   
                    region: res.data.region,   
                    bucket: res.data.bucket
                 })
            }
        }).catch(error => {
        })
    }
    opemPhotos(e){
        e.stopPropagation()
    }
    // 图片选中上传
    onImageChange(files, type, index){
        // console.log('选择图片',files)
        if(files&&files.length>0){
            files.forEach((item,index)=>{
                let file = item.file
                let nowTime = String(new Date().getFullYear()) + String(new Date().getMonth() + 1)
                let nowTime1 = String(new Date().getTime())
                let dname = file.name.substring(file.name.lastIndexOf(".")+1)
                let fileName = '/comment/' + nowTime + '/'+nowTime1+'.'+dname
                // let fileName = '/comment/' + nowTime + '/'+file.name
                client.multipartUpload(
                    fileName,   //放到filePhoto文件夹下                  
                    file,
                ).then(res => {                           
                    //上传结果处理，此处因为需要放开图片访问权限，所以还需要调用putACL方法处理一下
                    // console.log('图片上传成功',res)
                    client.putACL(res.name, 'public-read');
                    files[index].ossUrl = self.state.ossToken.fileDomain+res.name
                    self.setState({
                        imgContent: files[index].ossUrl
                    },()=>{
                        self.send(2)
                    })
                })
            })
        }
        self.setState({
            files,
            isShowMoreModule: false
        },()=>{
            console.log('文件列表',files)
        });
    }
    // 图片上传失败
    onFail(e){
        console.log('失败',e)
    }
    // 表情包
    showEmoji(e){
        e.stopPropagation()
        if(self.state.isShowEmoji){
            self.setState({
                isShowEmoji: false,
                isShowMoreModule: false,
                isShowGoods: false,
                isShowTextModule: false
            })
        }else{
            self.setState({
                isShowEmoji: true,
                isShowMoreModule: false,
                isShowGoods: false,
                isShowTextModule: false
            })
        }
    }
    // 选中表情
    getEmoji(res){
        let text = document.getElementById('imContent').innerHTML?document.getElementById('imContent').innerHTML:''
        document.getElementById('imContent').innerHTML = text + res.value
        self.setState({
            inputContent: text + res.value
        })
        // document.getElementById('imContent').focus()
    }
    // 商品列表
    showGoods(type,e){
        e.stopPropagation()
        self.setState({
            isShowMoreModule: false,
            typePick: type,
            isShowGoods: true,
            isShowTextModule: false
        })
    }
    cancelShowGoods(){
        self.setState({
            isShowGoods: false
        })
    }
    // 选中商品/订单
    getListId(res){
        let arr = {
            pid:res.data.pid,
            name:res.data.name,
            first_pic:res.data.first_pic,
        }
        if(String(res.type)==='1'){
            arr.order_id = res.data.order_id
            arr.order_number = res.data.order_number
            arr.money = res.data.total_cash
        }else{
            arr.money = res.data.sell_price
        }
        self.send(3,arr)
        self.setState({
            isShowGoods: false
        })
    }
    // 空白处隐藏
    cancelDialog(e){
        if(self.state.msgList&&self.state.msgList.length>0){
            let {msgList} = self.state
            msgList.forEach((item)=>{
                item.isRecall = false
            }) 
            self.setState({msgList})
        }
        self.setState({
            isShowGoods: false,
            isShowMoreModule: false,
            isShowTextModule: false,
            isShowEmoji: false
        })
    }
    // 查看大图
    showChatImg(url){
        self.setState({
            isShowChatImg: true,
            chatImgUrl: url
        })
    }
    // 关闭大图
    cancelChatImg(){
        self.setState({
            isShowChatImg: false,
            chatImgUrl: ''
        })
    }
    _onTouchStart(index,e){
        self.state.timeOutEvent = setTimeout(function() {
            e.nativeEvent.stopImmediatePropagation()
            if(self.state.identity){
                self.state.timeOutEvent = 0
                let {msgList} = self.state
                msgList[index].isRecall = true
                self.setState({ msgList })
            }
        }, 400)
    }
    _onTouchMove(index) {  
        clearTimeout(self.state.timeOutEvent);  
        self.state.timeOutEvent = 0;  
    } 
    _onTouchEnd(index) {  
        clearTimeout(self.state.timeOutEvent);  
        if (self.state.timeOutEvent !== 0) {  
        }  
        return false
    }  
    // 复制消息
    copyMsg(item,index){
        let {msgList} = self.state
        msgList[index].isRecall = false
        self.setState({ msgList })
        common.copy(item.content)
    }
    // 切换快捷回复类型
    changeKeFastType(type,e){
        e.stopPropagation()
        console.log(type,self.state.replyImgs)
        if(type!==self.state.keFastType){
            self.setState({
                keFastType:  type
            },()=>{
                if((type === 1&&self.state.replyData.list&&self.state.replyData.list.length>0)||(type === 2&&self.state.replyImgs.list&&self.state.replyImgs.list.length>0)){
                    return false
                }
                self.getFastReplyList('')
            })
        }
    }
    // 撤回消息
    recallMsg(item,index){
        // console.log(item,index)
       self.setState({
        recallPickItem: item,
        recallPickIndex: index,
        isCheckRecall: true
       })
    }
    // 确认撤回
    checkRecall(){
        let item = self.state.recallPickItem
        let index = self.state.recallPickIndex
         // 撤回消息
         let recallParams = {
            Type: 6,
            Source: String(self.state.source),
            Data: {
                "uid": String(self.state.uid),//用户编号（字符类型：string）
                "to_uid": String(self.state.tuid),//用户编号（字符类型：string）
                "id": String(item.id) // 消息id（字符类型：string）
            }
        }
        Socket.send(JSON.stringify(recallParams))
        let {msgList} = self.state
        msgList[index].isRecall = false
        msgList[index].isDelete = 1
        self.setState({ msgList, isCheckRecall: false })
    }
    // 取消撤回
    cancelRecall(){
        let index = self.state.recallPickIndex
        self.setState({
            isCheckRecall: false
        })
        let {msgList} = self.state
        msgList[index].isRecall = false
        self.setState({ msgList })
    }
    // 查看用户数据
    viewUser(item){
        if(self.state.identity&&item.uid!==self.state.uid){
            let search = '?tuid='+self.state.tuid+'&uid='+self.state.uid+'&source='+self.state.source
            self.props.history.push({ pathname: "/pages/im/user/index", search: search})
        }
    }
    reload(){
        window.location.reload()
    }
    // 监听div输入
    getInputContent(e){
        e.stopPropagation()
        let val = e.target.innerHTML&&e.target.innerHTML!=='<br>'?e.target.innerHTML:''
        self.setState({
            inputContent: val
        })
    }
    // 点击发送消息
    sendMsg(e){
        e.stopPropagation()
        self.send(1)
        self.setState({
            isShowEmoji: false
        })
    }
    // 人工客服提示
    showPerson(){
        let personStorage = localStorage.getItem('personStorage')
        if(personStorage&&personStorage!=='undefined'){

        }else{
            localStorage.setItem('personStorage','kefu')
            // Toast.show({
            //     content: '如果需要人工客服，请点击人工客服~',
            //     duration: 5000,
            // })
            Toast.info('如果需要人工客服，请点击人工客服~')
        }
    }
    showComment(){
        self.setState({
            isComment: true
        })
    }
    // 评价参数
    commentParams(res){
        console.log(res)
        self.setState({
            commentData: {
                solve: res.solve-1,
                content: res.score
            },
            isComment: false
        },()=>{
            self.send('6')
        })
    }
    // 关闭评价弹窗
    cancelComment(){
        self.setState({
            isComment: false
        })
    }
    // 商品详情
    linkGoods(id){
        // 监听跳转外部链接返回时刷新页面，解决微信往返缓存机制引起的websocket断开问题
        window.addEventListener('pageshow',function(event){
            if(event.persisted) window.location.reload()
        })
        if(self.state.identity&&Number(self.state.source)===1){
            window.location.href = 'https://h5.duojingshenghuo.com/goods/detail/index?id='+id
        }
        if(self.state.identity&&Number(self.state.source)===2){
            window.location.href = 'https://h5.xianningshenghuo.cn/pages/goods/detail?id'+id
        }
    }
    // 机器人回复
    changeChannel(obj){
        // console.log(obj) 
        // 原先二级才有回复数据 改成有没有二级问题 都直接上报请求回复数据
        self.setState({
            inputContent: obj.keyword
        },()=>{
            self.send(1)
            if(Number(obj.status)===1){
                // 没有二级问题 上报请求回复数据
                // self.setState({
                //     inputContent: obj.keyword
                // },()=>{
                //     self.send(1)
                // })
            }else{
                // 二级问题 显示二级机器人回复数据
                let {msgList, channelSecondData} = self.state
                let arr = {
                    msgType: "channelSecond",
                }
                msgList.push(arr)
                channelSecondData = obj.children
                self.setState({msgList,channelSecondData},()=>{
                    let timer1 = setTimeout(()=>{
                        document.getElementById('imMsgs').scrollTo(0,document.getElementById("imMsgs").scrollHeight)
                        clearTimeout(timer1)
                    },300)
                })
            }
        })
    }
    // 切换/分配人工客服
    changeKefu(){
        if(this.state.allUserInfo&&this.state.allUserInfo.toUser&&this.state.allUserInfo.toUser.uid>0){
            Toast.info('人工客服已连接，请描述您的问题')
        }else{
            let kefuMsgPrams = {
                Type: 12, // 分配人工客服
                Source: String(self.state.source),
                Data: {
                    "uid": String(self.state.uid),//用户编号（字符类型：string）
                    "key": self.state.nowUserKey,//当前用户前端唯一标识（字符类型：string）
                    "source": self.state.port?String(self.state.port):'',// 来源端 渠道分配（字符类型：string）
                }
            }
            Socket.send(JSON.stringify(kefuMsgPrams))
        }
    }
    // 换一换
    changeChannelPage(){
        let {channelShowpage,channelReplyPage} = self.state
        if(channelShowpage&&(channelShowpage.page*channelShowpage.prepage>=channelShowpage.count)){
            if(channelShowpage.count<=channelShowpage.prepage){
                return false
            }
            channelReplyPage = 1
            self.setState({
                channelReplyPage
            },()=>{
                self.getChannelReplyList()
            })
        }else{
            channelReplyPage = channelReplyPage + 1
            self.setState({
                channelReplyPage
            },()=>{
                self.getChannelReplyList()
            })
        }
    }
    cancelClose(){
        self.setState({
            isCloseRoute: false
        })
    }
    confirmClose(){
        self.setState({
            isCloseRoute: false
        })
    }

    render() {
        return ( 
            <React.Fragment>
                {this.state.isLoading?<Loading />:null}
                { 
                    self.state.isWSError
                    ?<div className='connect_warn' onClick={this.reload.bind(this)}>客服走丢了，请稍后刷新...<span className='reload_btn'>刷新</span></div>
                    :null
                }
                {
                    this.state.showData
                    ? <div className='im_index' id='imIndex' onClick={this.cancelDialog.bind(this)}>
                        { 
                            self.state.isLineUp
                            ?<div className='connect_warn'>{self.state.lineUpText}</div>
                            :null
                        }
                        { 
                            !self.state.isConnect
                            ?<div className='connect_warn' onClick={this.reload.bind(this)}>消息连接断开了，正在努力重连中...<span className='reload_btn'>刷新</span></div>
                            :null
                        }
                        {
                            !self.state.identity?<div className='im_title'>
                                <div className='title_span'>
                                    <span className='text'>
                                        {String(this.state.source)==='1'?'多鲸客服':'鲜柚客服'}
                                        {
                                            this.state.allUserInfo.toUser.uid>0
                                            ? <React.Fragment>
                                                &nbsp;·&nbsp;{this.state.allUserInfo.toUser.nickName}&nbsp;
                                            </React.Fragment>
                                            : null
                                        }
                                        将为您服务
                                    </span>
                                </div>
                            </div>:<div className='title_space'></div>
                        }
                        <div className='im_msgs' id='imMsgs'>
                            {
                                this.state.msgList.length>0
                                ? <React.Fragment>
                                    {
                                        this.state.msgList.map((item,index)=>{
                                            return <React.Fragment key={index}>
                                                {String(item.msgType)!=='5'?<React.Fragment>{self.msgFun(item,index)}</React.Fragment>:null}
                                            </React.Fragment>
                                        })
                                    }
                                </React.Fragment>
                                : null
                            }
                        </div>
                        <div className='im_footer'>
                            <div className='im_footer_btns flex_box'>
                                <img alt='' src={EasyIcon} className='btn_easy' onClick={this.showTextModule.bind(this)} />
                                <div className='input_bar fb1'>
                                    <div className='im_input'>
                                        <div contentEditable="true" id='imContent' className='im_textarea fb1' onFocus={self.getFocus.bind(this)} onInput={this.getInputContent.bind(this)}></div>
                                    </div>
                                    <img alt='' src={EmojiIcon} className='im_emoji' onClick={this.showEmoji.bind(this)} />
                                </div>
                                {
                                    this.state.inputContent
                                    ?<React.Fragment>
                                        <div className='send_btn' onClick={this.sendMsg.bind(this)}>发送</div>
                                    </React.Fragment>
                                    :<React.Fragment>
                                        {
                                            self.state.isShowMoreModule
                                            ? <img alt='' src={CancelIcon} className='btn_more' onClick={this.cancelMoreModule.bind(this)} />
                                            : <img alt='' src={MoreIcon} className='btn_more' onClick={this.showMoreModule.bind(this)} />
                                        }
                                    </React.Fragment>
                                }
                                {
                                    !this.state.identity
                                    ? <div className='btn_position'>
                                        <div className='kefu_enter' onClick={this.changeKefu.bind(this)}><img alt='' src={KefuImg} className='star_img' />人工客服</div>
                                        <div className='comment_enter' onClick={this.showComment.bind(this)}><img alt='' src={StarImg} className='star_img' />服务评价</div>
                                        <div className='order_enter' onClick={this.showGoods.bind(this,1)}><img alt='' src={OrderImg} className='star_img' />我的订单</div>
                                        <div className='goods_enter' onClick={this.showGoods.bind(this,2)}><img alt='' src={GoodsImg} className='star_img' />我的商品</div>
                                    </div>
                                    : null
                                }
                            </div>
                            {
                                this.state.isShowTextModule
                                ? <div className='more_module'>
                                    {
                                        this.state.identity&&this.state.replyTagList.length>0&&this.state.keFastType===1
                                        ? <div className='fast_text_menu_1'>
                                            <div className='tab_scroll'>
                                            {
                                                this.state.replyTagList.map((item)=>{
                                                    return <div className={this.state.replyTagLabel===item.label?'text_item active':'text_item'} onClick={this.getFastReplyList1.bind(this,item.label)}>{item.label}</div>
                                                })
                                            }
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div className='more_module_fl1'>
                                        {
                                            this.state.keFastType===1
                                            ? <React.Fragment>
                                                {
                                                    this.state.isReplyText?<div className='reply_text'>
                                                        {
                                                            this.state.isReplyText&&this.state.replyData.list.map((item,index)=>{
                                                                return <div className='text_module_li' key={index} onClick={this.pickText.bind(this,item)}>{item.message}</div>
                                                            })
                                                        }
                                                    </div>:null
                                                }
                                                
                                            </React.Fragment>
                                            : <React.Fragment>
                                                {
                                                    this.state.isReplyImgs?<div className='reply_imgs'>
                                                        {
                                                            this.state.isReplyImgs&&this.state.replyImgs.list.map((item,index)=>{
                                                                return <div className='pic_module_li' key={index} onClick={this.pickFastImg.bind(this,item)}>
                                                                    <img src={item.message} alt='' className='li_img' />
                                                                    <div className='li_img_name ellipsis_1'>{item.label}</div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>:null
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                    {
                                        this.state.identity
                                        ? <div className='fast_text_menu'>
                                            <div className={this.state.keFastType===1?'text_item active':'text_item'} onClick={this.changeKeFastType.bind(this,1)}>文案</div>
                                            <div className={this.state.keFastType===2?'text_item active':'text_item'} onClick={this.changeKeFastType.bind(this,2)}>图片</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {
                                this.state.isShowEmoji
                                ? <div className='more_module im_emoji'>
                                    <Emoji getEmoji={this.getEmoji.bind(this)} />
                                </div>
                                : null
                            }
                            {
                                this.state.isShowMoreModule
                                ? <div className='more_module other_module'>
                                    <div className='more_module_li clearfix' onClick={this.opemPhotos.bind(this)}>
                                        <img alt='' src={PhotoIcon} className='item_icon' />
                                        相册
                                        <div className='hide_picker'>
                                            <ImagePicker
                                                files={this.state.files}
                                                onChange={this.onImageChange.bind(this)}
                                                onFail={this.onFail}
                                                selectable={this.state.files.length < 2}
                                                length='4'
                                                multiple={this.state.multiple}
                                            />
                                        </div>
                                    </div>
                                    {
                                        !this.state.identity?<React.Fragment>
                                            <div className='more_module_li' onClick={self.showGoods.bind(this,1)}>
                                                <img alt='' src={IndentIcon} className='item_icon' />
                                                订单
                                            </div>
                                            <div className='more_module_li' onClick={self.showGoods.bind(this,2)}>
                                                <img alt='' src={GoodsIcon} className='item_icon' />
                                                商品
                                            </div>
                                        </React.Fragment>:null
                                    }
                                </div>
                                : null
                            }
                            {
                                this.state.isShowGoods
                                ? <GoodsList type={this.state.typePick} cancel={self.cancelShowGoods.bind(this)} getListId={self.getListId.bind(this)} />
                                : null
                            }
                            {
                                this.state.isShowChatImg
                                ? <div className='chat_img_big' onClick={this.cancelChatImg.bind(this)}>
                                    <img src={this.state.chatImgUrl} alt='' id='chatImgBig' className='chat_img' />
                                </div>
                                : null
                            }
                            {
                                this.state.isCheckRecall
                                ? <div className='check_recall'>
                                    <div className='shadow' onClick={this.cancelRecall}></div>
                                    <div className='check_recall_body'>
                                        <div className='check_recall_title'>是否撤回此讯息</div>
                                        <div className='check_recall_submit' onClick={this.checkRecall}>确定</div>
                                        <div className='check_recall_cancel' onClick={this.cancelRecall}>取消</div>
                                    </div>
                                </div>
                                : null
                            }
                            {
                                // 关闭页面提示
                                this.state.isCloseRoute ? <ConfirmBox title='' escText='取消' confirmText='确认退出' Cancel={this.cancelClose} Confirm={this.confirmClose}>
                                    <div className='close_content'>亲，退出客服界面后，人工服务需要重新排队哦</div>
                                </ConfirmBox> : null
                            }
                            {
                                this.state.isComment?<CommentTemp commentParams={this.commentParams.bind(this)} cancel={this.cancelComment.bind(this)} />:null
                            }
                            <div className='safe_area_inset_bottom'></div>
                        </div>
                    </div>
                    : null
                }
            </React.Fragment>
        )
    }
}
export default IMIndex


