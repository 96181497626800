/**
 * 网络请求配置
 */
import axios from "axios";
import common from '@utils/common.js'

axios.defaults.timeout = 100000;
// axios.defaults.baseURL = "http://edit.duojing.xianningshenghuo.cn/";
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data);
    config.headers = {
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    if (response.data.errCode === 2) {
    }
    return response;
  },
  (error) => {
  }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
        params: params,
      }).then((response) => {
        //landing(url, params, response.data);
        if (response.status === 200) {
          resolve(response.data);
        }else if(response.status === 299){
           // localStorage.removeItem('access_token')
            localStorage.removeItem('user_info_login')
          
          reject(response);
        }else{
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        //关闭进度条
       // resolve(response.data);
        if (response.status === 200) {
            resolve(response.data);
          }else if(response.status === 299){
            //localStorage.removeItem('access_token')
            localStorage.removeItem('user_info_login')
         
            reject(response);
          }else{
            reject(response);
          }
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}

//统一接口处理，返回数据

// eslint-disable-next-line import/no-anonymous-default-export
export default function (fecth, url, params) {
//   let _data = "";
    let cid = localStorage.getItem('cid')&&localStorage.getItem('cid')!=='undefined' ? localStorage.getItem('cid') : 1
    let fuid = common.getQueryVariable('fuid')?common.getQueryVariable('fuid'):''
    fuid = fuid?fuid:((localStorage.getItem('F_UID')&&localStorage.getItem('F_UID')!=='undefined')?localStorage.getItem('F_UID'):'')
    let _source = localStorage.getItem('_source')&&localStorage.getItem('_source')!=='undefined' ? localStorage.getItem('_source') : ''
    let token = common.getQueryVariable('token')?common.getQueryVariable('token'):'3|edb9f54740d4cd25b9957ebb26fa6db0'
    let source = common.getQueryVariable('source')?common.getQueryVariable('source'):'1'
    let address = localStorage.getItem('address')
    address = (address&&address!=='undefined')?JSON.parse(address):{}
    let pid = localStorage.getItem('PID')&&localStorage.getItem('PID')!=='undefined' ? localStorage.getItem('PID') : ''//最后访问商品
    if(!params){
      params = {}
    }
    if(pid!=='' && !params.pid){
      //params.pid=pid
      //params = Object.assign({pid:pid}, params);
    }
    if(params&&!params.cid){
      params.cid = cid
    }
    if(params&&!params.fuid){
      params.fuid = fuid
    }
    if(params&&!params.lat){
      params.lat = address.latitude
    }
    if(params&&!params.lng){
      params.lng = address.longitude
    }
    if(params&&!params.source){
      params.source = source
    }
    if(params&&!params.token){
      params.token = token
    }
    if(_source){
      params._source = _source
    }

    // var env = process.env.REACT_APP_SECRET_ENV;

    //只有测试调试环境 带Token 其余环境用COOKIE
    // if(env === "development"){
    //   let userInfoLogin =localStorage.getItem('user_info_login')
    //   let userInfo = (userInfoLogin&&userInfoLogin!=='undefined')?JSON.parse(userInfoLogin):{}
    //     params.token = userInfo.access_token
    // }

    //标记来源渠道
   // params._from = "H5"
    //let uid = '';
    let uid = localStorage.getItem('login_uid')
    if(uid === 'undefined' || uid === null){
        uid = ''
    }

    let requestId = guid();
    // if(pid!==''){
    //   url=url.replace('/api/','/api/pid'+pid+'/')
    // }
    //url = url + "?_uid=" + uid + "&requestId=" + requestId + "&_from=H5"
    params._uid = uid
    params.requestId = requestId
    params._from = 'H5'
    return new Promise((resolve, reject) => {
        switch (fecth) {
        case "get":
            get(url, params)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
            break;
        case "post":
            post(url, params)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
            break;
        default:
            break;
        }
    });
}

const guid = () =>{
  var d = Date.now();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r&0x3)|0x8)).toString(16);
  });
}

//失败提示
function msag(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        alert(err.response.data.error.details);
        break;
      case 401:
        alert("未授权，请登录");
        break;

      case 403:
        alert("拒绝访问");
        break;

      case 404:
        alert("请求地址出错");
        break;

      case 408:
        alert("请求超时");
        break;

      case 500:
        alert("服务器内部错误");
        break;

      case 501:
        alert("服务未实现");
        break;

      case 502:
        alert("网关错误");
        break;

      case 503:
        alert("服务不可用");
        break;

      case 504:
        alert("网关超时");
        break;

      case 505:
        alert("HTTP版本不受支持");
        break;
      default:
    }
  }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
// function landing(url, params, data) {
//   if (data.code === -1) {
//   }
// }