import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import App from './router/index'
import common from '@utils/common'
import "lib-flexible"
import store from './store/index'
import reportWebVitals from './reportWebVitals'
import './index.scss'

const history = createBrowserHistory()
if(common.getQueryVariable('vconsole')&& common.getQueryVariable('vconsole')==="1"){
  const VConsole = require('vconsole')
  // eslint-disable-next-line
  const my_console = new VConsole()
}
let source = common.getQueryVariable('source')?common.getQueryVariable('source') : ''
document.title = String(source)==='2'?'鲜柚客服':'多鲸客服'

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>
  ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
